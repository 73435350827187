import config from '../config'

export default {
    data() {
        return {
            colorsHtml: [
                '#2196F3', // blue
                '#F44336', // red
                '#4CAF50', // green
                '#FF9800', // orange
                '#9C27B0', // purple
                '#3F51B5', // indigo
                '#00BCD4', // cyan
                '#FFEB3B', // yellow
                '#795548', // brown
                '#E91E63', // pink
                '#673AB7', // deep-purple
                '#03A9F4', // light-blue
                '#009688', // teal
                '#8BC34A', // light-green
                '#FF5722', // deep-orange
                '#607D8B', // blue-grey
                '#666', // grey-1
                '#777', // grey-2
                '#888', // grey-3
                '#999', // grey-4
                '#aaa', // grey-5
                '#bbb', // grey-6
            ],
            CourierStatus: {
                free: 'free',
                processing: 'processing',
                delivering: 'delivering',
            },
        }
    },
    created: function() {
    },
    methods: {
        courierConnectColor(status) {
            return status ? '#4CAF50' : '#F44336'
        },
        courierConnectStatus(status) {
            return status ? 'Подключен' : 'Отключен'
        },
        courierStatusColorHtml(sta) {
            let color = 'grey'
            switch (sta) {
                case config.user.status.free:
                    color = '#4CAF50' // green
                    break
                case config.user.status.processing:
                    color = '#F44336' // red
                    break
                case config.user.status.delivering:
                    color = '#2196F3' // blue
                    break
            }
            return color
        },
        courierStatusColor(sta) {
            let color = 'grey'
            switch (sta) {
                case config.user.status.free:
                    color = 'green'
                    break
                case config.user.status.processing:
                    color = 'red'
                    break
                case config.user.status.delivering:
                    color = 'blue'
                    break
            }
            return color + '--text'
        },
        courierStatusIcon(sta) {
            let icon = 'mdi-question'
            switch (sta) {
                case config.user.status.free:
                    icon = 'mdi-check-bold'
                    break
                case config.user.status.processing:
                    icon = 'mdi-timer-sand'
                    break
                case config.user.status.delivering:
                    icon = 'mdi-run-fast'
                    break
            }
            return icon
        },
        courierStatusStr(sta) {
            let status = 'Неизвестно'
            switch (sta) {
                case config.user.status.free:
                    status = 'Свободен'
                    break
                case config.user.status.processing:
                    status = 'Обрабатывает'
                    break
                case config.user.status.delivering:
                    status = 'Доставляет'
                    break
            }
            return status
        },
        courierStatusColorHtml1(courier) {
            let color = 'grey'
            switch (courier.status) {
                case this.CourierStatus.free:
                    color = '#4CAF50' // green
                    break
                case this.CourierStatus.processing:
                    color = '#F44336' // red
                    break
                case this.CourierStatus.delivering:
                    color = '#2196F3' // blue
                    break
            }
            return color
        },
        courierStatusColor1(courier) {
            let color = 'grey'
            switch (courier.status) {
                case this.CourierStatus.free:
                    color = 'green'
                    break
                case this.CourierStatus.processing:
                    color = 'red'
                    break
                case this.CourierStatus.delivering:
                    color = 'blue'
                    break
            }
            return color + '--text'
        },
        courierStatusIcon1(courier) {
            let icon = 'mdi-question'
            switch (courier.status) {
                case this.CourierStatus.free:
                    icon = 'mdi-check-bold'
                    break
                case this.CourierStatus.processing:
                    icon = 'mdi-timer-sand'
                    break
                case this.CourierStatus.delivering:
                    icon = 'mdi-run-fast'
                    break
            }
            return icon
        },
        courierStatusStr1(courier) {
            let status = 'Неизвестно'
            switch (courier.status) {
                case this.CourierStatus.free:
                    status = 'Свободен'
                    break
                case this.CourierStatus.processing:
                    status = 'Обрабатывает'
                    break
                case this.CourierStatus.delivering:
                    status = 'Доставляет'
                    break
            }
            return status
        },
        workerStatusColor(sta) {
            let color = 'grey'
            switch (sta) {
                case config.user.status.free:
                    color = 'green'
                    break
                case config.user.status.delivering:
                    color = 'blue'
                    break
            }
            return color + '--text'
        },
        workerStatusIcon(sta) {
            let icon = 'mdi-question'
            switch (sta) {
                case config.user.status.free:
                    icon = 'mdi-check-bold'
                    break
                case config.user.status.delivering:
                    icon = 'mdi-hammer-screwdriver'
                    break
            }
            return icon
        },
        workerStatusStr(sta) {
            let status = 'Неизвестно'
            switch (sta) {
                case config.user.status.free:
                    status = 'Свободен'
                    break
                case config.user.status.delivering:
                    status = 'В работе'
                    break
            }
            return status
        },
        orderStatusColorHtml(sta) {
            let color = 'grey'
            switch (sta) {
                case config.order.status.new:
                    color = '#9C27B0' // purple
                    break
                case config.order.status.way_point:
                    color = '#FF9800' // orange
                    break
                case config.order.status.way_guest:
                    color = '#2196F3' // blue
                    break
                case config.order.status.complete:
                    color = '#4CAF50' // green
                    break
                case config.order.status.forming:
                    color = '#607D8B' // blue-grey
                    break
                case config.order.status.passed:
                    color = '#F44336' // red
                    break
                case config.order.status.pending:
                    color = '#F0F' // magenta
                    break
            }
            return color
        },
        orderStatusColor(sta,service=false) {
            let color = 'grey'
            switch (sta) {
                case config.order.status.new:
                    color = 'purple'
                    break
                case config.order.status.way_point:
                    color = 'orange'
                    break
                case config.order.status.way_guest:
                    color = 'blue'
                    break
                case config.order.status.complete:
                    color = 'green'
                    break
                case config.order.status.forming:
                    color = 'grey'
                    break
                case config.order.status.passed:
                    color = 'red'
                    break
                case config.order.status.pending:
                    color = 'magenta'
                    break
            }
            return color + '--text'
        },
        orderStatusIcon(sta,service=false) {
            let icon = 'mdi-question'
            switch (sta) {
                case config.order.status.new:
                    icon = 'mdi-flash'
                    break
                case config.order.status.way_point:
                    icon = 'mdi-run-fast'
                    break
                case config.order.status.way_guest:
                    icon = 'mdi-run-fast'
                    break
                case config.order.status.complete:
                    icon = 'mdi-check-bold'
                    break
                case config.order.status.forming:
                    icon = 'mdi-account-question'
                    break
                case config.order.status.passed:
                    icon = 'mdi-arrow-right-bold'
                    break
                case config.order.status.pending:
                    icon = 'mdi-alert-octagon-outline'
                    break
            }
            return icon
        },
        orderStatusStr(sta, service = false) {
            let status = 'Неизвестно'
            switch (sta) {
                case config.order.status.new:
                    status = 'Новый'
                    break
                case config.order.status.way_point:
                    status = service?'Исполняется':'В пути к точке'
                    break
                case config.order.status.way_guest:
                    status = service?'Исполняется':'В пути к гостю'
                    break
                case config.order.status.complete:
                    status = service?'Выполнен':'Доставлен'
                    break
                case config.order.status.forming:
                    status = 'Создается'
                    break
                case config.order.status.passed:
                    status = 'Назначен'
                    break
                case config.order.status.pending:
                    status = 'Ожидает назначения'
                    break
            }
            return status
        },
        
        iconPlatformSource(platform) {
            let icon = `${ require('../assets/images/darall_invert.png') }`
            if (platform == '2gis') {
                icon = `${ require('../assets/images/2gis.png') }`
            } else if (platform == 'yandex') {
                icon = `${ require('../assets/images/yandex.png') }`
            } else if (platform == 'google') {
                icon = `${ require('../assets/images/google.png') }`
            } else if (platform == 'tripadvisor') {
                icon = `${ require('../assets/images/tripadvisor.png') }`
            } else if (platform == 'vlru') {
                icon = `${ require('../assets/images/vlru.png') }`
            } else if (platform == 'instagram') {
                icon = `${ require('../assets/images/instagram.png') }`
            } else if (platform == 'tiktok') {
                icon = `${ require('../assets/images/tiktok.png') }`
            } else if (platform == 'telegram') {
                icon = `${ require('../assets/images/telegram.png') }`
            } else if (platform == 'zen') {
                icon = `${ require('../assets/images/zen.png') }`
            }
            return icon
        },
        iconPlatformSource2(platform) {
            let icon = `${ require('../assets/images/darall_invert.png') }`
            if (platform == 'Dgis') {
                icon = `${ require('../assets/images/2gis.png') }`
            } else if (platform == 'Yandex') {
                icon = `${ require('../assets/images/yandex.png') }`
            } else if (platform == 'Google') {
                icon = `${ require('../assets/images/google.png') }`
            } else if (platform == 'Trip') {
                icon = `${ require('../assets/images/tripadvisor.png') }`
            } else if (platform == 'Vlru') {
                icon = `${ require('../assets/images/vlru.png') }`
            } else if (platform == 'Instagram') {
                icon = `${ require('../assets/images/instagram.png') }`
            } else if (platform == 'Tiktok') {
                icon = `${ require('../assets/images/tiktok.png') }`
            } else if (platform == 'Telegram') {
                icon = `${ require('../assets/images/telegram.png') }`
            } else if (platform == 'Zen') {
                icon = `${ require('../assets/images/zen.png') }`
            }
            return icon
        },
        tooltipSource(order) {
            let tooltip = 'Unknown'
            if (order.source == config.order.source.bot) {
                tooltip = 'Darall Bot'
            } else if (order.source == config.order.source.iiko) {
                tooltip = 'iiko'
            } else if (order.source == config.order.source.iikoBiz) {
                tooltip = 'iikoBiz'
            } else if (order.source == config.order.source.mail) {
                tooltip = 'Mail'
            } else if (order.source == config.order.source.market) {
                tooltip = 'Маркетплейс'
            } else if (order.source == config.order.source.web) {
                tooltip = 'Darall Web'
            } else if (order.source == config.order.source.hook) {
                tooltip = 'Webhook'
            }
            return tooltip
        },
        iconSource(order) {
            let icon = ''
            if (order.source == config.order.source.bot) {
                icon = `${ require('../assets/images/telegram-48.jpg') }`
            } else if (order.source == config.order.source.iiko) {
                icon = `${ require('../assets/images/iiko-48.jpg') }`
            } else if (order.source == config.order.source.iikoBiz) {
                icon = `${ require('../assets/images/iikoBiz-48.jpg') }`
            } else if (order.source == config.order.source.mail) {
                icon = `${ require('../assets/images/mail-48.jpg') }`
            } else if (order.source == config.order.source.market) {
                icon = `${ require('../assets/images/market-48.jpg') }`
            } else if (order.source == config.order.source.web) {
                icon = `${ require('../assets/images/web-48.jpg') }`
            } else if (order.source == config.order.source.hook) {
                icon = `${ require('../assets/images/webhook-48.jpg') }`
            }
            return icon
        },
        respondTooltip(review) {
            let text
            if (review.respond_text) {
                text = 'Ответ отправлен'
            } else {
                if (review.value > 4) {
                    text = 'Положительный отзыв без ответа'
                } else {
                    text = 'Негативный отзыв без ответа'
                }
            }
            return text
        },
        respondColorHtml(review) {
            let color
            if (review.respond_text) {
                color = '#4CAF50' // green
            } else if (review.value > 4) {
                // color = '#FF9800' // orange
                color = '#607D8B' // blue-grey
                // color = '#9E9E9E' // grey
            } else {
                color = '#F44336' // red
            }
            return color
        },
        respondIcon(review) {
            let icon
            if (review.respond_text) {
                icon = 'mdi-message-plus-outline'
            } else {
                icon = 'mdi-message-alert-outline'
            }
            return icon
        },
    }
}